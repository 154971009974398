import React, { useState, useContext, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import Luxury from "../../assets/images/luxury.jpeg";
import SearchBarWithDropdown from "../SearchBarWIthDropdownStates";
import { IMAGE_PATH } from "../../constant";

import assetContext from "../../context/AssetsContext/AssetContext";
import Verified from "../../assets/images/verified.png";

import Slider1 from "../../assets/images/slider1.png";
import Slider2 from "../../assets/images/slider2.png";

// import TimeAgo from "../../components/TimeAgo"

const SliderHome = () => {
  const navigate = useNavigate();

  const handleDataReceived = async (data) => {
    const filtersx = data.filters;
    const queryParams = new URLSearchParams({
      page: 1,
      property_type: filtersx !== null ? filtersx.property_type : [],
      property_status: filtersx !== null ? filtersx.property_status : [],
      city: filtersx !== null ? filtersx.city : [],
      property_builder: filtersx !== null ? filtersx.property_builder : [],
      bedroom: filtersx !== null ? filtersx.bedroom : [],
      property_price: filtersx !== null ? filtersx.property_price : 20,

      bath_rooms: filtersx !== null ? filtersx.bath_rooms : [],
      balconies: filtersx !== null ? filtersx.balconies : [],
      door_facing: filtersx !== null ? filtersx.door_facing : [],
      furnishing_status: filtersx !== null ? filtersx.furnishing_status : [],
      parking: filtersx !== null ? filtersx.parking : [],

      property_name: data.text,
    }).toString();

    navigate(`/properties?${queryParams}`);
  };

  const context = useContext(assetContext);
  const {
    getAvailableFilters,
    availableFilters,
    assetsfeatured,
    totalAssetsFeatured,
    getAssetsFeatured,
  } = context;
  useEffect(() => {
    // getAssets({ page: currentPage });
    getAvailableFilters();

    getAssetsFeatured({
      page: 1,
    });

    // eslint-disable-next-line
  }, []);

  const sliderList = [
    {
      image: Slider1,

      title: "Seamless Living, Timeless Spaces.",
      desc: "RESELL HOMES",
    },
    {
      image: Slider2,
      title: "Empowering Resell Buyers and Sellers in One Platform",
      desc: "BUY  -  SELL",
    },
  ];

  const [isExpanded, setIsExpanded] = useState(false);

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          // color: "white",
          right: 5,
          zIndex: 5,
          backgroundColor: "grey",

          borderRadius: 10,
          width: 30,
          height: 30,
        }}
        onClick={onClick}
      />
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          // display: "block",
          display: "flex",
          justifyContent: "center",
          //  marginLeft: "15px",
          left: 5,
          zIndex: 5,
          alignItems: "center",
          color: "white",
          backgroundColor: "grey",
          borderRadius: 10,
          width: 30,
          height: 30,
        }}
        onClick={onClick}
      />
    );
  }

  const [showDiv, setShowDiv] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(min-width: 900px)"); // Example media query for width <= 768px

    const handleMediaQueryChange = (e) => {
      setShowDiv(e.matches);
    };

    // Initial check
    handleMediaQueryChange(mediaQuery);

    // Add listener for media query changes
    mediaQuery.addListener(handleMediaQueryChange);

    // Clean up listener on component unmount
    return () => {
      mediaQuery.addListener(handleMediaQueryChange);
    };
  }, []);

  const [defaultImage, setDefaultImage] = useState({});

  const settings = {
    // dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          //  dots: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          // dots: true,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          // dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      //   {
      //     breakpoint: 480,
      //     settings: {
      //       slidesToShow: 1,
      //       slidesToScroll: 1,
      //     },
      //   },
    ],
  };

  // const showALL = async () => {
  //   const queryParams = new URLSearchParams(filters).toString();
  //   navigate(`/${localStorage.getItem("city")}/properties?${queryParams}`);
  // };

  const handleErrorImage = (data) => {
    setDefaultImage((prev) => ({
      ...prev,
      [data.target.alt]: data.target.alt,
      linkDefault: Luxury,
    }));
  };

  // const HandleClick = (slug) => {
  //   window.open(`/property/${slug}`, "_blank");
  //   //   navigate(`/${localStorage.getItem("city")}/property/${id}/${name}`);
  //   localStorage.setItem("projectData", JSON.stringify(assetsfeatured));
  // };

  return (
    <div style={{ maxWidth: "100%", margin: "auto", position: "relative" }}>
      <Slider {...settings}>
        {sliderList.map((slide, index) => (
          <div key={index}>
            <div
              style={{
                position: "relative",
                //height: "70vh",
                backgroundImage: `url(${slide.image})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              className="h-[70vh]"
            >
              {/* Text content */}
              <div
                style={{
                  position: "absolute",
                  // top: "20%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  color: "white",
                  textAlign: "center",
                  // backgroundColor: "rgba(0, 0, 0, 0.5)", // for text background transparency
                  padding: "20px",
                  borderRadius: "10px",
                  // width: "80%",
                }}
                className="w-[90vw] lg:w-full top-[30%] lg:top-[50%]"
              >
                <h2
                  style={{ marginBottom: "10px" }}
                  className="text-lg sm:text-2xl"
                >
                  {slide.title}
                </h2>
                <p
                  className="text-3xl sm:text-5xl"
                  style={{
                    fontFamily: "Playfair Display,sans-serif",
                  }}
                >
                  {slide.desc}
                </p>
                <div>
                  <SearchBarWithDropdown
                    onDataReceived={handleDataReceived}
                    isAbsolute={true}
                    locationaOptions={
                      availableFilters !== null &&
                      availableFilters.property_location.length > 0
                        ? availableFilters.property_location
                        : []
                    }
                    configOptions={
                      availableFilters !== null &&
                      availableFilters.property_bhk.length > 0
                        ? availableFilters.property_bhk
                        : []
                    }
                    style={{  width: "100%",  }}
                  />
                </div>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default SliderHome;
