import React from "react";
import Slider1 from "../../assets/images/slider1.png";
import BuyHome from "../../assets/icons/buyhome2.png";
import SellHome from "../../assets/icons/sellhome1.png";
import { PRIMARY_COLOR } from "../../constant";
import Navbar from "../Navbar";
import { FaNewspaper } from "react-icons/fa6";
import { IoIosMailOpen } from "react-icons/io";
import { FaFacebookF } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { IconButton } from "@mui/material";
import ResaleHomesPost from "../../assets/images/resealehomespost.png";
import Footer from "../footer";
const AllBlogs = () => {
  const newsList = [
    {
      title:
        "Making Smart Investment: A Deep Dive into resell.home's Property Validation Process.",
      desc: "",
      date: "Sep 26 2024",
    },
    {
      title:
        "The Art of Property Validation, Why resell.homes Leads the Way in Quality Listing.",
      desc: "",
      date: "Sep 29 2024",
    },
    {
      title: "Navigating the Bumppy Summertime Real Estate Market.",
      desc: "",
      date: "Sep 26 2024",
    },
    {
      title:
        "Investing in Opulence, Why resell.homes is Your Source for 2+ Crore Properties.",
      desc: "",
      date: "Sep 26 2024",
    },
    {
      title:
        "Elevate Your Real Estate Game with resell.homes Premium Property Listing.",
      desc: "",
      date: "Sep 26 2024",
    },
    {
      title:
        "Making Smart Investment: A Deep Dive into resell.home's Property Validation Process.",
      desc: "",
      date: "Sep 26 2024",
    },
    {
      title:
        "The Art of Property Validation, Why resell.homes Leads the Way in Quality Listing.",
      desc: "",
      date: "Sep 29 2024",
    },
    {
      title: "Navigating the Bumppy Summertime Real Estate Market.",
      desc: "",
      date: "Sep 26 2024",
    },
    {
      title:
        "Investing in Opulence, Why resell.homes is Your Source for 2+ Crore Properties.",
      desc: "",
      date: "Sep 26 2024",
    },
    {
      title:
        "Elevate Your Real Estate Game with resell.homes Premium Property Listing.",
      desc: "",
      date: "Sep 26 2024",
    },
  ];

  const openSocialMedia = (id) => {
    const urls = {
      1: "https://www.facebook.com/profile.php?id=100094976210846", // Add more URLs as needed
      2: "https://www.twitter.com", // Add more URLs as needed
      3: "https://www.linkedin.com/company/resell-homes/posts/?feedView=all", // Add more URLs as needed
      4: "https://www.youtube.com/@homznoffiz", // Add more URLs as needed
      5: "https://www.instagram.com/resell.homes/", // Add more URLs as needed
    };

    // Open the URL in a new tab
    window.open(urls[id], "_blank");
  };

  return (
    <div
      style={
        {
          //display:"flex"
        }
      }
    >
      <Navbar />
      <div
        style={{
          position: "relative",
          backgroundImage: `url(${Slider1})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        className="h-[50vh] md:h-[70vh]"
      >
        {/* Text content */}
        <div
          style={{
            position: "absolute",
            // top: "20%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            color: "white",
            textAlign: "center",
            // backgroundColor: "rgba(0, 0, 0, 0.5)", // for text background transparency
            padding: "20px",
            borderRadius: "10px",
            // width: "80%",
          }}
          className="w-[90vw] lg:w-full top-[50%] md:top-[50%]"
        >
          <h2 style={{ marginBottom: "10px" }} className="text-lg sm:text-2xl">
            "News"
          </h2>
          <p
            className="text-3xl sm:text-5xl"
            style={{
              fontFamily: "Playfair Display,sans-serif",
            }}
          >
            RESELL HOMES
          </p>
          <h2 style={{ marginBottom: "10px" }} className="text-lg sm:text-2xl">
            Resell Redefining
          </h2>
        </div>

        <div
          style={{
            position: "absolute",
            bottom: -200,
            // marginBottom: 50,
          }}
          className="hidden md:flex   mx-auto gap-y-20 gap-x-40 px-10"
        >
          <div
            style={{
              backgroundColor: "white",
              borderRadius: "10px",
              padding: "20px",
              // width: "80%",
              // maxWidth: "40vw",
              textAlign: "center",
              zIndex: 2,
              //backdropFilter: "blur(5px)",
              ///boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
              //color: "#fff",
            }}
            className="flex  w-[35vw] lg:w-[25vw] justify-center items-center flex-col   border border-white border-1"
          >
            <img src={BuyHome} />
            <h2
              style={{
                marginTop: "10px",
                //  fontSize: "30px",
                fontWeight: 600,
                //color:"#9379B8",
                fontFamily: "Poppins, sans-serif",
              }}
              className="text-xl md:text-3xl"
            >
              Buy
            </h2>

            <p
              style={{
                margin: "10px 0px",
                // fontSize: "18px",
                fontWeight: 500,
                paddingLeft: 25,
                paddingRight: 25,
                fontFamily: "Poppins, sans-serif",
                color: "#5f6063",
              }}
              className="text-sm"
            >
              Find your dream home from our extensive listings, tailored to your
              preferences. Our advanced filters make your search easier than
              ever.
            </p>
            <a
              href="/properties"
              style={{
                fontSize: 14,
                fontFamily: "Poppins, sans-serif",
              }}
              className="text-sm md:text-xl flex items-center mt-10 mb-4 justify-center gap-x-1 py-2 px-4 text-black font-medium rounded-md border-black border-2 md:inline-flex"
            >
              BROWSE LISTING
            </a>
          </div>

          <div
            style={{
              backgroundColor: "white",
              borderRadius: "10px",
              padding: "20px",
              //marginLeft: 10,
              // width: "80%",
              // maxWidth: "40vw",
              textAlign: "center",
              zIndex: 2,
              //backdropFilter: "blur(5px)",
              ///boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
              //color: "#fff",
            }}
            className="flex w-[35vw] lg:w-[25vw] justify-center items-center flex-col   border border-white border-1"
          >
            <img src={SellHome} />
            <h2
              style={{
                marginTop: "10px",
                //  fontSize: "30px",
                fontWeight: 600,
                //color:"#9379B8",
                fontFamily: "Poppins, sans-serif",
              }}
              className="text-xl md:text-3xl"
            >
              Sell
            </h2>

            <p
              style={{
                margin: "10px 0px",
                // fontSize: "18px",
                fontWeight: 500,
                paddingLeft: 25,
                paddingRight: 25,
                fontFamily: "Poppins, sans-serif",
                color: "#5f6063",
              }}
              className="text-sm"
            >
              List your property with ease and let our experts guide you in
              attracting the right buyers at the best price.
            </p>
            <a
              href="/contact"
              style={{
                fontSize: 14,
                fontFamily: "Poppins, sans-serif",
              }}
              className="text-sm md:text-xl flex items-center mt-10 mb-4 justify-center gap-x-1 py-2 px-4 text-black font-medium rounded-md border-black border-2 md:inline-flex"
            >
              LIST MY PROPERTY
            </a>
          </div>
        </div>
      </div>

      {newsList !== null ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: 10,
          }}
        >
          <div className="flex flex-col lg:flex-row max-w-8xl sm:w-[70vw] mt-[50px] md:mt-[250px] gap-x-20 flex justify-center">
            <div className="flex flex-col">
              <div
                style={{
                  color: "black",
                  fontWeight: 600,
                  marginBottom: 10,
                  flex: 1,
                  fontSize: 20,
                }}
              >
                Recent News Release
              </div>
              {newsList.map((news, index) => (
                <div className="hover:bg-[#f9f2f2] p-10 w-full flex flex-col justify-start items-start">
                  <h1
                    style={{
                      fontFamily: "EB Garamond, sans-serif",
                      fontSize: 14,
                      color: "grey",
                    }}
                  >
                    {news.date}
                  </h1>
                  <h1
                    style={{
                      fontFamily: "EB Garamond, sans-serif",
                      fontSize: "1.5rem",
                    }}
                  >
                    {news.title}
                  </h1>
                  <div
                    onClick="/"
                    style={{
                      padding: "8px 15px",
                      backgroundColor: PRIMARY_COLOR,
                      color: "white",
                      borderRadius: 4,
                      marginTop: 8,
                      // fontFamily: "EB Garamond, sans-serif",
                      fontSize: 14,
                      fontStyle: "revert",
                      fontWeight: "bold",
                    }}
                  >
                    See Full Story
                  </div>
                </div>
              ))}
              <div
                style={{
                  color: PRIMARY_COLOR,
                  fontWeight: 600,
                  marginBottom: 200,

                  fontSize: 14,
                  marginTop: 25,
                }}
                className="border-b-2 border-red-500 "
              >
                SEE MORE RELEASES
              </div>
            </div>

            <div className="flex flex-col   items-center">
              <div
                style={{
                  backgroundColor: "#F7F5F4",
                  height: 250,
                  padding: 30,
                  marginLeft: 10,
                  borderRadius: 10,
                  display: "flex",
                  flexDirection: "column",
                  // justifyContent:"space-around",
                  // alignItems:"start",

                  position: "sticky", // Make the div sticky
                  top: "20vh", // Set the top offset when it should start sticking
                  right: "14.5vw",
                   //  zIndex: 30,
                }}
                className="w-[90vw]  lg:w-[20vw] shadow-md hidden lg:block"
              >
                <div
                  style={{
                    color: "black",
                    fontWeight: 500,
                    margin: 10,
                    flex: 1,
                    fontFamily: "Poppins, sans-serif",
                    fontSize: "1.5rem",
                  }}
                >
                  Contact
                </div>

                <div
                  style={{
                    color: "[#242124]",
                    margin: 10,
                    flex: 1,
                    fontFamily: "Inter, sans-serif",
                    fontSize: "1rem",
                    lineHeight: 2,
                  }}
                >
                  Working on a story? Email us, and the PR team will follow up!
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                    marginLeft: 10,
                  }}
                >
                  <IoIosMailOpen size={24} />
                  <label
                    style={{
                      color: "#293F66",
                      fontWeight: 600,
                      fontFamily: "Inter, sans-serif",
                      fontSize: 14,
                      marginBottom: 5,
                      marginLeft: 5,
                    }}
                    className="mt-2 underline"
                  >
                    NEWS@RESELL.HOMES
                  </label>
                </div>
              </div>
              <div
                style={{
                  backgroundColor: "#F7F5F4",

                  height: 200,
                  padding: 30,
                  marginLeft: 10,
                  marginTop: 50,
                  borderRadius: 10,
                  display: "flex",
                  flexDirection: "column",
                  // justifyContent:"space-around",
                  // alignItems:"start",

                  position: "sticky", // Make the div sticky
                  top: "50vh", // Set the top offset when it should start sticking
                  right: "14.5vw",
                  //  zIndex: 30,
                }}
                className="w-[90vw]  lg:w-[20vw] shadow-md hidden lg:block"
              >
                <div
                  style={{
                    color: "black",
                    fontWeight: 500,
                    margin: 10,
                    flex: 1,
                    fontFamily: "Poppins, sans-serif",
                    fontSize: "1.5rem",
                  }}
                >
                  Connect
                </div>

                <div
                  style={{
                    display: "flex",
                    marginLeft: 10,
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <IconButton
                    type="submit"
                    edge="start"
                    //disabled={checked.length === 0 ? true : false}
                    onClick={() => openSocialMedia(1)}
                  >
                    <FaFacebookF color="#293F66" />
                  </IconButton>

                  <IconButton
                    type="submit"
                    edge="start"
                    //disabled={checked.length === 0 ? true : false}
                    onClick={() => openSocialMedia(2)}
                  >
                    <FaTwitter color="#293F66" />
                  </IconButton>

                  <IconButton
                    type="submit"
                    edge="start"
                    //disabled={checked.length === 0 ? true : false}
                    onClick={() => openSocialMedia(3)}
                  >
                    <FaLinkedinIn color="#293F66" />
                  </IconButton>

                  <IconButton
                    type="submit"
                    edge="start"
                    //disabled={checked.length === 0 ? true : false}
                    onClick={() => openSocialMedia(4)}
                  >
                    <FaYoutube color="#293F66" />
                  </IconButton>

                  <IconButton
                    type="submit"
                    edge="start"
                    //disabled={checked.length === 0 ? true : false}
                    onClick={() => openSocialMedia(5)}
                  >
                    <FaInstagram color="#293F66" />
                  </IconButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      <Footer />
    </div>
  );
};

export default AllBlogs;
